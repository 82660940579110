import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Accordion from '../../../components/Accordion.js'
import Layout from '../../../components/layout'

const NorthAll = ({data}) => {
    return <Layout>
        <Helmet
            htmlAttributes={{
                        lang: "en"
            }}>
            <title>North Indian Pandit in Bangalore (15+yrs Exp):Book Hindi Pandit in Bangalore, North Indian Pandits in Bangalore, North Indian Pandit Ji in Bangalore, North Indian Pandit Bangalore, Kumaoni Pandit in Bangalore, Garhwali Pandit in Bangalore & Uttarakhand Pandit in Bangalore, UP Pandit in Bangalore, Bengali Pandit in Bangalore & Oriya Pandit in Bangalore for all Puja</title>
            <meta name="description" content="Looking for North Indian Pandit in Bangalore? We provide the best Hindi Pandit in Bangalore, North Indian Pandits in Bangalore, North Indian Pandit Ji in Bangalore, North Indian Pandit Bangalore for all types of ceremony & poojas. We also Provide Kumaoni Pandit in Bangalore, Garhwali Pandit in Bangalore & Uttarakhand Pandit in Bangalore, UP Pandit in Bangalore, Bengali Pandit in Bangalore & Oriya Pandit in Bangalore/Bengaluru, Karnataka." />
            <script type="application/ld+json">{`
                 {
                  "@context":"https://schema.org",
                  "@type":"FAQPage",
                  "mainEntity": [
                    {
                      "@type":"Question",
                      "name":"Should the customer arrange for Puja Samagri or would the Panditji arrange?",
                      "acceptedAnswer": 
                      {
                        "@type":"Answer",
                        "text":"Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja"
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Will the Panditji help the customer in choosing good muhurth for ritual?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Would the Puja be performed as per the North Indian traditions?",
                      "acceptedAnswer":
                      {  
                        "@type":"Answer",
                        "text":"Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from various parts of North India such as UP, Uttarakhand, Bihar, Jharkhand, West Bengal etc. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How can the customers pay? Through cash or Wallet or Netbanking?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)"
                      }
                    }
                  ]
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "ImageObject",
                  "author": "NamasteGod",
                  "name": "${data.allMarkdownRemark.edges[2].node.frontmatter.title}",
                  "contentUrl": "https://www.namastegod.com${data.allFile.nodes[2].childImageSharp.fluid.src}",
                  "contentLocation": "Bangalore, India"
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Product",
                  "name": "North Indian Pandits in Bangalore",
                  "image": "https://www.namastegod.com${data.allFile.nodes[2].childImageSharp.fluid.src}",
                  "description": "Looking for North Indian Pandits in Bangalore? We provide the best Hindi Pandit in Bangalore, North Indian Pandit in Bangalore, North Indian Pandit Ji in Bangalore, North Indian Pandit Bangalore for all types of ceremony & poojas. We also Provide Kumaoni Pandit in Bangalore, Garhwali Pandit in Bangalore & Uttarakhand Pandit in Bangalore/Bengaluru, Karnataka.",
                  "sku": "0000000001",
                  "mpn": "000001",
                  "brand": {
                    "@type": "Brand",
                    "name": "NamasteGod"
                  },
                  "review": {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5",
                      "bestRating": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rekha Anoop"
                    }
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "101"
                  },
                  "offers": {
                    "@type": "Offer",
                    "url": "https://www.namastegod.com/findonline/hindu/north-indian-hindi-speaking-pandit-ji-for-puja-in-bangalore-india-for-marriage-wedding-vedic-hindu-kumaoni-kashmiri-priests",
                    "priceCurrency": "INR",
                    "price": "3100",
                    "priceValidUntil": "2020-12-31",
                    "itemCondition": "https://schema.org/UsedCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                      "@type": "Organization",
                      "name": "NamasteGod"
                    }
                  }
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "LocalBusiness",
                  "name": "North Indian Pandits in Bangalore",
                  "telephone": "(+91) 81972 49957",
                  "priceRange": "₹₹₹",
                  "url": "https://www.namastegod.com/findonline/hindu/north-indian-hindi-speaking-pandit-ji-for-puja-in-bangalore-india-for-marriage-wedding-vedic-hindu-kumaoni-kashmiri-priests",
                  "image": "https://www.namastegod.com${data.allFile.nodes[2].childImageSharp.fluid.src}",
                  "address":
                  {
                    "@type": "PostalAddress",
                    "addressLocality": "Bangalore",
                    "addressRegion": "KA",
                    "addressCountry": "IN",
                    "postalCode": "560078",
                    "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                   }
                }
            `}</script>
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">North Indian Pandit in Bangalore (15+yrs Exp):<br/>Book Hindi Pandit in Bangalore for all Pujas</h1>
                        <h1 className="mobile">North Indian Pandit in Bangalore<br/>(15+yrs Exp):Book Hindi Pandit<br/>in Bangalore for all Pujas</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Looking for North Indian Pandit in Bangalore? We provide the best Hindi Pandit in Bangalore & North Indian Pandits in Bangalore for all types of ceremony & poojas. We also Provide Vedic Hindu priests, Kashmiri, Garhwali, Kumaoni <Link to="/findonline/hindu/north-indian-pandit-in-whitefield-bangalore" className="no-highlight">Pandits</Link> in Bangalore & Uttarakhand pandit in Bangalore, UP Pandit in Bangalore, Bengali Pandit in Bangalore & Oriya Pandit in Bangalore/Bengaluru, Karnataka. We also provide services such as North Indian <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Pandit</a> for Kundli Matching in Bangalore, <a href='https://www.northindianpanditinbangalore.co.in/' className='no-highlight'>North Indian Pandit</a> Astrologer in Bangalore and North Indian Vastu Consultant in Bangalore.</p>
                    <Link className="button special" to="#contact">Contact Us</Link>
                    <Accordion
          id="1"
          question=" more"
          answer="<h2>Top 5 North Indian Pandits in Bangalore</h2>
          <table>
            <tbody>
                <tr>
                    <th>Name</th><th>Region</th>
                    <th>Yrs of Exp</th><th>Pujas Done</th>
                </tr>
                <tr>
                    <td>Pandit Hem Chandra Joshi</td>
                    <td>Kumaoni, Garhwali, Uttrakhand, UP</td>
                    <td>12+</td>
                    <td>3000+</td>
                </tr>
                <tr>
                    <td>Pandit Ashutosh Pandey</td><td>UP, Bihar, Jharkhand</td>
                    <td>10+</td><td>2500+</td>
                </tr>
                <tr>
                    <td>Pandit Subodh Pandey</td>
                    <td>Bihar, UP, Odisha, Bengal</td><td>15+</td>
                    <td>3000+</td>
                </tr>
                <tr>
                    <td>Pandit Sudhakar Pandey</td>
                    <td>Bihar, UP, Jharkhand</td>
                    <td>10+</td>
                    <td>2000+</td>
                </tr>
                <tr>
                    <td>Pandit Prashant Pandey</td>
                    <td>Bihar, UP, Bengal</td>
                    <td>13+</td>
                    <td>3000+</td>
                </tr>
            </tbody>
        </table>
        <p>North Indian Pandits in Bangalore sector is considered to be quite popular due to their niche and specialized puja traditions and vidan as per the North Indian culture and taste. These North Indian Pandits had moved to Bangalore from their respective hometowns such as Uttar Pradesh (UP), Bihar, Uttrakhand after successful completion of their Shastri and Jyotish Acharya degrees. These North Indian Pandits who have come to Bangalore had also underwent several trainings in Puja Path, Vidi, Vidan from esteemed Gurujis. These North Indian Pandits had been settled in Bangalore for more than half a decade and had traveled even to the farthest locations in Bangalore. Call Us Now to Book a North Indian Pandit.
</p>
<h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>Would the Puja be performed as per the North Indian traditions?
<span>Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from various parts of North India such as UP, Uttarakhand, Bihar, Jharkhand, West Bengal etc. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title_hindi}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order_north}) {
    edges {
      node {
        frontmatter {
          order_north
          title
          title_hindi
          experience
          degree
          regions
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Hem Chandra Joshi", "Pandit TPrashanth Pandey", "Pandit Subodh Pandey", "Pandit Ashutosh Pandey", "Pandit TSudharkar Pandey", "Pandit UDiwakar Pandey"]}} , sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default NorthAll